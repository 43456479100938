export const CORRECTION_FILE_STATUSES = [
    {
        id: 1,
        value: 0,
        label: "Saved",
    },
    {
        id: 2,
        value: 1,
        label: "Corrections imported",
    },
    {
        id: 3,
        value: 2,
        label: "Processed",
    }
];